import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import { FaArrowRight } from 'react-icons/fa'

import gridStyles from 'styles/grid.module.scss'
import styles from './Headlines.module.scss'

const query = graphql`
  query Headlines {
    wordpressAcfOptions {
      options {
        headlines {
          title
        }
        headline_cards {
          title
          image {
            source_url
          }
          description
          cta_url_text
          cta_url
        }
      }
    }
  }
`

export const Headlines = ({
  wordpressAcfOptions: {
    options: {
      headline_cards,
      headlines: { title },
    },
  },
}) => (
  <div className={styles.headlinesSection}>
    <div className={gridStyles.lbhContainer}>
      <div className={gridStyles.lbhRow}>
        <div className={gridStyles.lbhColumnFull}>
          <h2>{title}</h2>
        </div>
      </div>
      <div className={gridStyles.lbhRow}>
        <ul className={styles.headlineCards}>
          {headline_cards.map(card => (
            <li
              key={card.title}
              className={cx(gridStyles.lbhColumnOneThird, styles.cardWrapper)}
            >
              <a href={card.cta_url} className={styles.card}>
                <div
                  className={styles.cardImage}
                  style={{
                    backgroundImage:
                      card.image && `url(${card.image.source_url})`,
                  }}
                ></div>
                <div className={styles.cardTitle}>{card.title}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: card.description,
                  }}
                  className={styles.cardExcerpt}
                />
                <div className={styles.cardCta}>
                  {card.cta_url_text}
                  <FaArrowRight />
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
)

Headlines.propTypes = {
  wordpressAcfOptions: PropTypes.shape({
    options: PropTypes.shape({
      headlines: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
      headline_cards: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          cta_url_text: PropTypes.string.isRequired,
          cta_url: PropTypes.string.isRequired,
          description: PropTypes.node.isRequired,
          image: PropTypes.shape({
            source_url: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

const HeadlinesWithStaticQuery = props => (
  <StaticQuery
    query={query}
    render={data => Headlines({ ...data, ...props })}
  />
)

export default HeadlinesWithStaticQuery
