import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Layout from 'components/Layout/Layout'
import HomeAnnouncements from 'components/Announcements/HomeAnnouncements'
import Hero from 'components/Hero/Hero'
import { BlocksWithStaticQuery } from 'components/PageBuilder/components/Blocks/Blocks'
import Headlines from 'components/Headlines/Headlines'
import News from 'components/News/News'
import SocialMedia from 'components/SocialMedia/SocialMedia'
import SocialLinks from 'components/SocialLinks/SocialLinks'

import gridStyles from 'styles/grid.module.scss'
import indexStyles from './index.module.scss'

const RootIndex = ({ location }) => {
  return (
    <Layout location={location} announcements={<HomeAnnouncements />}>
      <div className={indexStyles.homePageSections}>
        <Hero />
        <div className={indexStyles.ctaBar}>
          <div className={gridStyles.lbhContainer}>
            <div className={gridStyles.lbhRow}>
              <Link to="/menu" className={indexStyles.ctaButton}>
                <div className={indexStyles.servicesHamburger}>
                  <div className={indexStyles.servicesHamburgerLine}></div>
                  <div className={indexStyles.servicesHamburgerLine}></div>
                  <div className={indexStyles.servicesHamburgerLine}></div>
                </div>
                <div className={indexStyles.servicesText}>Council services</div>
              </Link>
              <SocialLinks className={indexStyles.socialCtaBar} />
            </div>
          </div>
        </div>
        <Headlines />
        <BlocksWithStaticQuery />
        <News />
        <SocialMedia />
      </div>
    </Layout>
  )
}

RootIndex.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default RootIndex
