import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { StaticQuery, graphql } from 'gatsby'

import styles from './Announcements.module.scss'
import gridStyles from 'styles/grid.module.scss'

const query = graphql`
  query HomeAnnouncements {
    wordpressAcfOptions {
      options {
        announcements_home_page {
          announcement
          type
          expire
          color
          bordercolor
          textcolor
        }
      }
    }
  }
`

const now = new Date().getTime()

const getNotExpiredAnnouncements = (announcements, now) =>
  announcements.filter(
    announcement =>
      !announcement.expire || new Date(announcement.expire).getTime() >= now
  )

export const Announcements = ({
  wordpressAcfOptions: {
    options: { announcements_home_page },
  },
}) => {
  const [announcementsToShow, setAnnoucementsToShow] = useState(
    announcements_home_page.filter(announcement => !announcement.expire)
  )
  useEffect(() => {
    setAnnoucementsToShow(
      getNotExpiredAnnouncements(announcements_home_page, now)
    )
  }, [])
  return (
    <Fragment>
      {announcementsToShow.map(
        (
          { announcement, type, expire, color, textcolor, bordercolor },
          key
        ) => (
          <div
            key={key}
            style={{
              backgroundColor: `${color ?? ''}`,
              color: `${textcolor ?? ''}`,
              borderColor: `${bordercolor ?? ''}`,
            }}
            className={cx(styles.announcement, {
              [styles.announcementSitewide]: type === 'site',
              [styles.announcementSectionwide]: type === 'section',
              [styles.announcementWithExpire]: expire,
            })}
          >
            <div className={gridStyles.lbhRow}>
              <div
                className={gridStyles.lbhContainer}
                dangerouslySetInnerHTML={{ __html: announcement }}
              />
            </div>
          </div>
        )
      )}
    </Fragment>
  )
}

Announcements.propTypes = {
  wordpressAcfOptions: PropTypes.shape({
    options: PropTypes.shape({
      announcements_home_page: PropTypes.arrayOf(
        PropTypes.shape({
          announcement: PropTypes.node.isRequired,
          type: PropTypes.string.isRequired,
          expire: PropTypes.string,
          color: PropTypes.string,
          bordercolor: PropTypes.string,
          textcolor: PropTypes.string,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

const HomeAnnouncementsWithStaticQuery = props => (
  <StaticQuery
    query={query}
    render={data => Announcements({ ...data, ...props })}
  />
)

export default HomeAnnouncementsWithStaticQuery
