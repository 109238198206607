import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Button.module.scss'

const Button = ({ className, type, onClick, children }) => {
  return (
    <button
      className={cx(
        styles.button,
        { [styles.secondary]: type === 'secondary' },
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['secondary']),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default Button
