import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FaRegArrowAltCircleRight, FaCalendarAlt } from 'react-icons/fa'
import dateFormat from 'dateformat'

import { forceHttps } from 'utils/urls'
import gridStyles from 'styles/grid.module.scss'

import styles from './NewsOthers.module.scss'

const NewsFeatured = ({ news }) => (
  <div className={styles.container}>
    {news.map(newsItem => (
      <div
        key={newsItem.title}
        className={cx(gridStyles.lbhColumnOneFourth, styles.column)}
      >
        <a href={newsItem.link} className={styles.newsItem}>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${forceHttps(newsItem.enclosure.url)})`,
            }}
            alt={newsItem.title}
          ></div>
          <div className={styles.description}>
            <p
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html: newsItem.title,
              }}
            />
            <div
              className={styles.excerpt}
              dangerouslySetInnerHTML={{
                __html: newsItem.contentSnippet,
              }}
            />
          </div>
          <div className={styles.date}>
            <FaCalendarAlt />
            {dateFormat(newsItem.pubDate, 'dS mmmm yyyy')}
          </div>
          <FaRegArrowAltCircleRight className={styles.arrow} />
        </a>
      </div>
    ))}
  </div>
)

NewsFeatured.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      pubDate: PropTypes.string.isRequired,
      contentSnippet: PropTypes.string.isRequired,
      enclosure: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default NewsFeatured
