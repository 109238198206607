import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { StaticQuery, graphql } from 'gatsby'

import { flatQuery } from 'utils/queries'

import Button from 'components/Button/Button'
import NewsFeatured from './NewsFeatured/NewsFeatured'
import NewsOthers from './NewsOthers/NewsOthers'

import styles from './News.module.scss'
import gridStyles from 'styles/grid.module.scss'

const query = graphql`
  query News {
    wordpressAcfOptions {
      options {
        council_news {
          title
        }
      }
    }
    allFeedGatsbyBlog(limit: 6) {
      edges {
        node {
          title
          link
          pubDate
          enclosure {
            url
          }
          contentSnippet
        }
      }
    }
  }
`

export const News = ({
  allFeedGatsbyBlog,
  wordpressAcfOptions: {
    options: {
      council_news: { title },
    },
  },
}) => {
  const news = flatQuery(allFeedGatsbyBlog)
  return (
    <div className={gridStyles.lbhContainerOuternAlt}>
      <div className={gridStyles.lbhHomeContainer}>
        <h2>{title}</h2>
        <NewsFeatured news={news.slice(0, 2)} />
        <NewsOthers news={news.slice(2, 6)} />
        <a
          className={cx(styles.readMore, gridStyles.lbhRow)}
          href="https://news.hackney.gov.uk"
        >
          <Button>Read all news about Hackney Council</Button>
        </a>
      </div>
    </div>
  )
}

News.propTypes = {
  wordpressAcfOptions: PropTypes.shape({
    options: PropTypes.shape({
      council_news: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  allFeedGatsbyBlog: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          title: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
          pubDate: PropTypes.string.isRequired,
          contentSnippet: PropTypes.string.isRequired,
          enclosure: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
}

const NewsWithStaticQuery = props => (
  <StaticQuery query={query} render={data => News({ ...data, ...props })} />
)

export default NewsWithStaticQuery
