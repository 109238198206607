import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FaCalendarAlt } from 'react-icons/fa'
import dateFormat from 'dateformat'

import { forceHttps } from 'utils/urls'

import Button from 'components/Button/Button'

import styles from './NewsFeatured.module.scss'

const NewsFeatured = ({ news }) => (
  <div className={styles.container}>
    {news.map((newsItem, index) => (
      <a
        key={newsItem.title}
        href={newsItem.link}
        className={cx(styles.news, {
          [styles.alternate]: index % 2,
        })}
        tabIndex={-1}
      >
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${forceHttps(newsItem.enclosure.url)})`,
          }}
        ></div>
        <div className={styles.description}>
          <h3
            dangerouslySetInnerHTML={{
              __html: newsItem.title,
            }}
          />
          <div className={styles.date}>
            <FaCalendarAlt />
            {dateFormat(newsItem.pubDate, 'dS mmmm yyyy')}
          </div>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: newsItem.contentSnippet,
            }}
          />
          <Button className={styles.readmore}>Read more</Button>
        </div>
      </a>
    ))}
  </div>
)

NewsFeatured.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      pubDate: PropTypes.string.isRequired,
      contentSnippet: PropTypes.string.isRequired,
      enclosure: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default NewsFeatured
