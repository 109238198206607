import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { graphql, StaticQuery } from 'gatsby'

import gridStyles from 'styles/grid.module.scss'
import styles from './Blocks.module.scss'
import btnStyles from 'components/Button/Button.module.scss'

export const query = graphql`
  fragment blocksFragment on WordPressAcf_blocks {
    blocks {
      icon {
        source_url
      }
      title
      description
      cta_url_text
      cta_url
    }
    blocks_title
  }
`

export const acfOptionsQuery = graphql`
  query FindoutMore {
    wordpressAcfOptions {
      options {
        find_out_more {
          title
        }
        find_out_more_cards {
          icon {
            source_url
          }
          title
          description
          cta_url_text
          cta_url
        }
      }
    }
  }
`

const Blocks = ({ blocks, blocks_title, is_home }) => (
  <div
    className={cx(styles.blocksSection, {
      [styles.blocksSectionHome]: is_home,
    })}
  >
    <div className={cx({ [gridStyles.lbhHomeContainer]: is_home })}>
      {blocks_title && blocks_title.length > 0 && (
        <div className={gridStyles.lbhRow}>
          <div className={gridStyles.lbhColumnFull}>
            <h2>{blocks_title}</h2>
          </div>
        </div>
      )}
      <div className={gridStyles.lbhRow}>
        {blocks.map(block => (
          <div key={block.title} className={gridStyles.lbhColumnOneThird}>
            <div
              className={styles.blocksCard}
              style={{
                backgroundImage: block.icon && `url(${block.icon.source_url})`,
              }}
            >
              {block.title && <h3>{block.title}</h3>}
              {block.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: block.description,
                  }}
                  className={styles.content}
                />
              )}
              {block.cta_url && block.cta_url_text && (
                <a
                  href={block.cta_url}
                  className={`${btnStyles.secondary} ${btnStyles.button}`}
                >
                  {block.cta_url_text}
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)

Blocks.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      cta_url_text: PropTypes.string,
      cta_url: PropTypes.string,
      description: PropTypes.node,
      icon: PropTypes.shape({
        source_url: PropTypes.string,
      }),
    }).isRequired
  ).isRequired,
  blocks_title: PropTypes.string,
  is_home: PropTypes.bool.isRequired,
}

export default Blocks

const BlocksWithStaticQuery = props => (
  <StaticQuery
    query={acfOptionsQuery}
    render={data =>
      Blocks({
        blocks: data.wordpressAcfOptions.options.find_out_more_cards,
        blocks_title: data.wordpressAcfOptions.options.find_out_more.title,
        is_home: true,
        ...props,
      })
    }
  />
)

export { BlocksWithStaticQuery }
