import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { StaticQuery, graphql } from 'gatsby'

import SocialIcon from 'components/SocialIcon/SocialIcon'

import LogoHackney from 'assets/LogoHackney'

import gridStyles from 'styles/grid.module.scss'
import styles from './SocialMedia.module.scss'

const query = graphql`
  query SocialMedia {
    wordpressAcfOptions {
      options {
        social_media {
          title
        }
        social_media_cards {
          tag
          social_media_handle
          embed_code
        }
      }
    }
  }
`

export const SocialMedia = ({
  wordpressAcfOptions: {
    options: {
      social_media_cards,
      social_media: { title },
    },
  },
}) => (
  <div className={cx(gridStyles.lbhContainerOuternAlt, styles.container)}>
    <div className={gridStyles.lbhHomeContainer}>
      <h2>{title}</h2>
      <div className={gridStyles.lbhRow}>
        {social_media_cards.map(social => {
          const socialName = social.social_media_handle.toLowerCase()
          return (
            <div
              key={socialName}
              className={cx(gridStyles.lbhColumnOneThird, styles.socialName)}
            >
              <div className={styles.item}>
                <div>
                  <div className={cx(styles.hackneyBlackLogo, 'black')}>
                    <LogoHackney suffix={socialName} />
                    <span>{social.tag}</span>
                  </div>
                  <span className={styles.icon}>
                    {<SocialIcon name={socialName} alt={socialName} />}
                  </span>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: social.embed_code,
                  }}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  </div>
)

SocialMedia.propTypes = {
  wordpressAcfOptions: PropTypes.shape({
    options: PropTypes.shape({
      social_media: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
      social_media_cards: PropTypes.arrayOf(
        PropTypes.shape({
          embed_code: PropTypes.node.isRequired,
          social_media_handle: PropTypes.string.isRequired,
          tag: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

const SocialMediaWithStaticQuery = props => (
  <StaticQuery
    query={query}
    render={data => SocialMedia({ ...data, ...props })}
  />
)

export default SocialMediaWithStaticQuery
