import React from 'react'

import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql } from 'gatsby'
import { Parallax } from 'react-parallax'
import { FaArrowRight } from 'react-icons/fa'

import styles from './Hero.module.scss'
import gridStyles from 'styles/grid.module.scss'

const query = graphql`
  query Hero {
    wordpressAcfOptions {
      options {
        hero_banner {
          source_url
        }
        hero_title
        hero_intro_text
        hero_title_and_intro_link
        popular_tasks_title
        popular_tasks {
          cta_url_text
          cta_url
        }
      }
    }
  }
`

export const Hero = ({ wordpressAcfOptions: { options } }) => {
  const viewPortWidth = typeof window !== `undefined` ? window.viewPortWidth : 0
  return (
    <Parallax
      className={styles.heroSection}
      blur={0}
      bgImage={options.hero_banner.source_url}
      bgImageAlt="Hackney Town Hall"
      strength={viewPortWidth <= 991 ? 1500 : 200}
    >
      <div className={styles.heroContent}>
        <div className={gridStyles.lbhContainer}>
          <div className={gridStyles.lbhRow}>
            <div className={gridStyles.lbhColumnTwoThirds}>
              <a
                href={options.hero_title_and_intro_link}
                className={styles.heroLink}
              >
                <h1 className={styles.heroTitle}>{options.hero_title}</h1>
                <div>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: options.hero_intro_text,
                    }}
                  />{' '}
                  <FaArrowRight style={{ height: '0.75rem' }} />
                </div>
              </a>
            </div>
            <div className={gridStyles.lbhColumnOneThird}>
              <div className={styles.heroBox}>
                <p className={styles.heroBoxTitle}>
                  {options.popular_tasks_title}
                </p>
                <ul className={styles.heroBoxList}>
                  {options.popular_tasks.map(link => (
                    <li
                      key={link.cta_url_text}
                      className={styles.heroBoxListItem}
                    >
                      <a
                        href={link.cta_url}
                        className={styles.heroBoxListItemLink}
                      >
                        {link.cta_url_text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <Link to="/menu" className={styles.heroCTA}>
                <div className={styles.heroCTAHamburger} aria-hidden="true">
                  <div className={styles.heroCTAHamburgerLine}></div>
                  <div className={styles.heroCTAHamburgerLine}></div>
                  <div className={styles.heroCTAHamburgerLine}></div>
                </div>
                <div className={styles.heroCTAText}>Council services</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  )
}

Hero.propTypes = {
  wordpressAcfOptions: PropTypes.shape({
    options: PropTypes.shape({
      hero_title_and_intro_link: PropTypes.string.isRequired,
      hero_intro_text: PropTypes.string.isRequired,
      popular_tasks_title: PropTypes.string.isRequired,
      hero_title: PropTypes.string.isRequired,
      hero_banner: PropTypes.shape({
        source_url: PropTypes.string.isRequired,
      }).isRequired,
      popular_tasks: PropTypes.arrayOf(
        PropTypes.shape({
          cta_url: PropTypes.string.isRequired,
          cta_url_text: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

const HeroWithStaticQuery = props => (
  <StaticQuery query={query} render={data => Hero({ ...data, ...props })} />
)

export default HeroWithStaticQuery
